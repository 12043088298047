<template>
    <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{ label }}
            <info-icon v-if="enableHelp" :v-b-modal="helpModalId" @click="$emit('toggleModalHelp')"></info-icon>
        </label>
        <div class="col-sm-9">
            <input v-if="inputKind == 'text'" :name="id" :id="id" class="form-control pl-5" :placeholder="placeholder"
                :value="value" @input="$emit('input', $event.target.value)">

            <select v-if="inputKind == 'select'" :name="id" :id="id" class="form-control" :placeholder="placeholder"
                :value="value" @input="$emit('input', $event.target.value)">
                <option v-for="(opt) in options" :value="opt.value">{{ " " + opt.text }} </option>
            </select>


            <b-form-group v-if="inputKind == 'radiobuttons2'">
                <b-form-radio-group :name="id" :id="id" style="width:100%" :options="options" v-model="selected"
                    @change="$emit('input', $event)" @input="$emit('input', $event)" buttons></b-form-radio-group>
            </b-form-group>

            <b-form-radio-group v-if="inputKind == 'radiobuttons'" style="width:100%" buttons :name="id" :id="id">
                <b-form-radio v-for="option in options" :value="option.value" :id="id + '_' + option.value" v-model="selected"
                    @change="$emit('input', $event)" @input="$emit('input', $event)" button>
                    {{ option.text }}
                </b-form-radio>
                <b-tooltip v-for="option in withTooltips" :target="id + '_' + option.value" placement="top" trigers="hover">
                    {{ option.help }}
                </b-tooltip>
            </b-form-radio-group>


        </div>
    </div>
</template>


<script>
import { BookIcon, InfoIcon } from "vue-feather-icons";
export default {
    name: "FileProcInput",
    components: {
        BookIcon,
        InfoIcon
    },
    methods: {
        debug(event) {
            console.log(event);
        },
    },
    props: ['id', 'options', 'label', 'placeholder', 'value', 'enableHelp', 'helpModalId', 'inputKind'],
    data() {
        return {
            selected: '',
        };
    },
    computed: {
        withTooltips() {
            return this.options.filter(row => "help" in row)
        },
    }
}
</script>